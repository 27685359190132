import { IActionContext } from '@msdyn365-commerce/core';
import { AttributeDataType, ProductRefinerSource, ProductRefinerValue } from '@msdyn365-commerce/retail-proxy';
interface StoreCookie {
    StoreId: string,
    AttributeId: number
}
const getStoreRefiner = (ctx: IActionContext): ProductRefinerValue | undefined => {
    const storeCookie = ctx.requestContext.cookies.get('FILTER_STORES');
    if (storeCookie && storeCookie.value) {
        const val = storeCookie.value as StoreCookie;
        return {
            RefinerRecordId: val.AttributeId,
            DataTypeValue: AttributeDataType.Text,
            LeftValueBoundString: val.StoreId,
            RightValueBoundString: val.StoreId,
            RefinerSourceValue: ProductRefinerSource.Attribute
        };
    }
    return undefined;
};

export default getStoreRefiner;
